import React from "react"
import { Page, Layout, BodyText, Headline } from '@servicetitan/design-system';
import { GlobalWrapper, Link, SEO } from '../components';

const Page404 = () => (
	<GlobalWrapper>
		<SEO title="404" />
		<Page
			header={
				<Headline>
					<br />
					404
					<br />
					Something's gone wrong.
				</Headline>
			}
			className="DocsPage"
		>
			<Layout
				type="support"
				direction="right"
				className="DocsPage__layout"
				style={{marginTop: 36}}
			>
				<Layout.Section>
					<BodyText>Sorry, we can’t find the page you are looking for. Maybe some of these most visited links will help you?</BodyText>
					<ul>
						<li><Link href="/">Anvil Homepage</Link></li>
						<li><Link href="/foundations/principles/">Design Principles</Link></li>
					</ul>
				</Layout.Section>
			</Layout>
		</Page>
	</GlobalWrapper>
);

export default Page404;
